import { Flex, Stack } from '@chakra-ui/layout'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Tooltip } from '@irishlife/ilgroupdesignsystem.atoms.tooltip'
import {
    Box,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
    makeStyles,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { Text as TextDs} from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import { useDesktop } from 'common/hooks/useDesktop'
import { useSfdrProductsAndFunds } from 'common/hooks/useKidsDocuments'
import { downloadSFDRDocument } from 'common/utils/downloadKidsDocument'
import _ from 'lodash'
import { parse } from 'query-string'
import React, { useCallback, useEffect, useState } from 'react'
import { Jumbotron, JumbotronProps } from '../Jumbotron'
import { ListboxComponent } from './AutocompleteInput'

export interface KIDSProps {
    jumbotron: JumbotronProps
    layout: LayoutProps
}

export interface SfdrFund {
    fundCode: string
    fundName: string
    sfdrArticleIndicator: string
    primaryFundName: string
}

export interface Fund {
    productCode: string
    productName: string
    sfdrFunds: SfdrFund[]
}
type FundFilter = {
    productName: string
    fundName: string
}
export interface FundOptions {
    productName: string[]
    fundName: SfdrFund[]
}

const useStyles = makeStyles({
    listbox: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
    article8: {
        width: 0,
        height: 0,
        borderLeft: '3px solid transparent',
        borderRight: '3px solid transparent',
        borderBottom: '5px solid limegreen',
        scale: 2.5,
        position: 'relative',
        top: '10px',
    },
    article9: {
        width: 0,
        height: 0,
        borderLeft: '3px solid transparent',
        borderRight: '3px solid transparent',
        borderBottom: '5px solid darkgreen',
        scale: 2.5,
        position: 'relative',
        top: '10px',
    },
})

export const SFDR: React.FC<KIDSProps> = ({ jumbotron, layout }) => {
    const desktop = useDesktop()
    const funds: Fund[] = useSfdrProductsAndFunds()

    const initialFilter = {
        productName: '',
        fundName: '',
    }
    let queryString = {} as { id: string }

    if (typeof window !== 'undefined')
        queryString = parse(window?.location.search) as { id: '' }

    const [fundId, setFundId] = useState<string | null>(
        queryString.id ? queryString.id.toString() : null
    )
    const [filter, setFilter] = useState(initialFilter)
    const [sfdrFunds, setSfdrFunds] = useState<SfdrFund[]>()
    const [activeFund, setActiveFund] = useState<SfdrFund | null>(null)
    const [allFundNames, setAllFundNames] = useState<string[]>([])
    const [filteredFundNames, setFilteredFundNames] = useState<string[]>([])

    const classes = useStyles()

    const sortSfdrFunds = (a: SfdrFund, b: SfdrFund) => {
        return a.fundName.localeCompare(b.fundName)
    }

    const getOptions = ({ productName, fundName }: FundFilter): FundOptions => {
        return {
            productName: _.uniq(
                funds
                    .map((fund) => fund.productName)
                    .sort((a, b) => {
                        return a > b ? 1 : -1
                    })
            ),
            fundName: productName
                ? _.uniq(
                      _.filter(funds, {
                          productName,
                      }).map((fund) => fund.sfdrFunds)
                  )
                      .flat()
                      .sort(sortSfdrFunds)
                : [],
        }
    }

    const getFunds = useCallback(() => {
        if (filter.productName !== '') {
            let singleFund = _.uniq(
                _.filter(funds, {
                    productName: filter.productName,
                }).map((fund) => fund.sfdrFunds)
            )
                .flat()
                .sort(sortSfdrFunds)

            let results = _.uniqBy(
                singleFund.sort(sortSfdrFunds),
                (fund: SfdrFund) => {
                    return fund.fundName
                }
            )
            setFilteredFundNames(results.map((item) => item.fundName))
            return results
        } else if (filter.productName === '') {
            let singleFund = _.uniq(
                funds.map((fund) => {
                    return fund.sfdrFunds.filter(
                        (item) => item.fundName === filter.fundName
                    )
                })
            ).flat()
            if (singleFund.length > 0) {
                return [singleFund[0]]
            }
        } else return []
    }, [filter.productName, filter.fundName, funds])

    const getSfdrFundList = useCallback(() => {
        if (activeFund) {
            setSfdrFunds([activeFund])
            setActiveFund(null)
        }
        if (filter.fundName !== '') {
            let singleFund = _.filter(getFunds(), { fundName: filter.fundName })
            setSfdrFunds(singleFund)
            return singleFund
        }
        let product = _.filter(funds, { productName: filter.productName }).pop()
        if (
            _.isNull(product) ||
            _.isUndefined(product) ||
            product.productName === ''
        ) {
            return []
        } else {
            // Sort funds alphabetically by comparison on the fund name key, returning a unique list via _.uniqBy
            setSfdrFunds(
                _.uniqBy(
                    product.sfdrFunds.sort(sortSfdrFunds),
                    (fund: SfdrFund) => {
                        return fund.fundName
                    }
                )
            )
            setFilteredFundNames(
                product.sfdrFunds.map((fund) => {
                    return fund.fundName
                })
            )
        }
    }, [filter, funds, getFunds, activeFund])

    const getAllFundNames = useCallback(() => {
        let results = []

        if (funds.length > 0) {
            results = funds.map((fund) => {
                return fund.sfdrFunds
                    .map((child) => {
                        return child.fundName
                    })
                    .flat()
            })
            setAllFundNames(_.uniq(results.flat().sort()))
            return
        }
    }, [funds])

    const handleProductFilterChange = (
        event: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
        if (event.target.value) {
            setFilter({
                productName: event.target.value as string,
                fundName: '',
            })
        }
    }

    const handleFundFilterChange = (
        event: React.ChangeEvent<{}>,
        value: string | null
    ) => {
        if (value) {
            setFilter({
                productName: filter.productName,
                fundName: value as string,
            })
            return value
        }
    }

    const clearFilter = () => {
        queryString = {} as { id: string }
        setActiveFund(null)
        setFundId(null)
        setFilter(initialFilter)
        setSfdrFunds([])
        setFilteredFundNames([])
    }

    useEffect(() => {
        if (funds.length === 0) return
        if (_.isNull(fundId)) {
            return
        }
        let queryResults: Fund = _.filter(funds, {
            sfdrFunds: [{ fundCode: fundId }],
        })[0]
        let singleResult = _.filter(queryResults.sfdrFunds, {
            fundCode: fundId,
        })[0]
        setActiveFund(singleResult)
    }, [funds, setActiveFund, fundId])

    useEffect(() => {
        getSfdrFundList()
    }, [filter, getSfdrFundList])

    useEffect(() => {
        getAllFundNames()
    }, [funds, getAllFundNames])

    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />

            <Spacer variant='md' />

            <Wrapper>
                <Box m={desktop ? 0 : 6} component='section'>
                    <Text
                        label='Search documents'
                        component='h1'
                        variant='h4'
                        box={{ mb: 4 }}
                    />
                    <TextDs mb='6' color='#3A3160'>
                    There are different variations of products with the same name and the funds available to you will depend on your version of that product. The funds available on your product may also change from time to time. For the correct most up to date list of funds available on your product version, please speak to your Financial Advisor or Broker or log in to your online account.<br/> The name of the fund in your SFDR disclosure document may be different from the fund name you see in your other documents because our funds can have different names. Although the name may be different, this information applies to your fund.<br/> For some fund managers other than Irish Life Investment Managers the pre-contractual disclosure document may be contained in the fund prospectus of the fund manager.
                    </TextDs>

                    <Box
                        borderRadius={10}
                        bgcolor='#fff'
                        display='flex'
                        width='100%'
                        px={6}
                        mb={8}
                        py={6}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Box
                                    flexDirection='column'
                                    display='flex'
                                    width='100%'
                                    mr={6}
                                >
                                    <Text
                                        box={{ mb: 2 }}
                                        label='Product Name'
                                        variant='body2'
                                    />
                                    <Select
                                        onChange={handleProductFilterChange}
                                        placeholder='Select Product Type'
                                        value={filter.productName}
                                        variant='outlined'
                                    >
                                        {getOptions(filter).productName.map(
                                            (productName) => (
                                                <MenuItem
                                                    key={productName}
                                                    value={productName}
                                                >
                                                    {productName}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                    mr={6}
                                    width='100%'
                                >
                                    <Text
                                        box={{ mb: 2 }}
                                        label='Fund Name'
                                        variant='body2'
                                    />
                                    <Autocomplete
                                        id='virtualize-demo'
                                        style={{ width: desktop ? 400 : 300 }}
                                        disableListWrap
                                        classes={classes}
                                        ListboxComponent={
                                            ListboxComponent as React.ComponentType<
                                                React.HTMLAttributes<HTMLElement>
                                            >
                                        }
                                        onChange={handleFundFilterChange}
                                        options={
                                            filter.productName === ''
                                                ? allFundNames
                                                : filteredFundNames
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant='outlined'
                                            />
                                        )}
                                        value={filter.fundName}
                                        renderOption={(option: string) => (
                                            <Typography noWrap>
                                                {option}
                                            </Typography>
                                        )}
                                    />
                                </Box>
                            </Grid>

                            <Box flex={1} />
                            <Box
                                display='flex'
                                flexDirection='row'
                                style={{ marginTop: 30 }}
                            >
                                <Button
                                    // fullWidth
                                    onClick={clearFilter}
                                    variant='secondary'
                                    style={{
                                        border: 'none',
                                        height: 54,
                                        textTransform: 'unset',
                                    }}
                                >
                                    Clear
                                </Button>
                            </Box>
                        </Grid>
                    </Box>

                    {sfdrFunds
                        ? sfdrFunds.map((fund) => {
                              return (
                                  <Box
                                      style={{
                                          backgroundColor: '#fff',
                                          margin: '1.5em',
                                          padding: '1.5em',
                                      }}
                                      key={fund.fundCode}
                                  >
                                      <Flex
                                          justifyContent='space-between'
                                          flexFlow={desktop ? 'row' : 'column'}
                                      >
                                          <Stack sx={{ flexGrow: '2' }}>
                                              <div style={{ display: 'flex' }}>
                                                  <Text
                                                      label={fund.fundName}
                                                      variant='h6'
                                                      style={{
                                                          fontSize: '1.2em',
                                                          maxWidth: '35ch',
                                                      }}
                                                  />
                                                  <Box
                                                      width='20%'
                                                      display={'flex'}
                                                      justifyContent={'center'}
                                                      alignItems={'t'}
                                                  >
                                                      {fund.sfdrArticleIndicator ===
                                                      '8' ? (
                                                          <Tooltip
                                                              label={
                                                                  'Article 8 fund'
                                                              }
                                                              variant='dark'
                                                              placement='top-start'
                                                          >
                                                              <div
                                                                  className={
                                                                      classes.article8
                                                                  }
                                                              ></div>
                                                          </Tooltip>
                                                      ) : fund.sfdrArticleIndicator ===
                                                        '9' ? (
                                                          <Tooltip
                                                              label={
                                                                  'Article 9 fund'
                                                              }
                                                              variant='dark'
                                                              placement='top-start'
                                                          >
                                                              <div
                                                                  className={
                                                                      classes.article9
                                                                  }
                                                              ></div>
                                                          </Tooltip>
                                                      ) : (
                                                          ''
                                                      )}
                                                  </Box>
                                              </div>
                                              <Text
                                                  label={`${filter.productName} / ${fund.fundName}`}
                                                  variant='caption'
                                                  style={{ color: '#615A80' }}
                                              />
                                              {fund.primaryFundName &&
                                              fund.primaryFundName !==
                                                  fund.fundName ? (
                                                  <Text
                                                      label={`This fund is also known as ${fund.primaryFundName}`}
                                                      variant='caption'
                                                      style={{
                                                          color: '#615A80',
                                                      }}
                                                  />
                                              ) : (
                                                  ''
                                              )}
                                          </Stack>
                                          <Stack flexBasis='20%' px={'5px'}>
                                              <Text
                                                  label='Website Disclosure'
                                                  variant='caption'
                                              />
                                              <Button
                                                  variant='secondary'
                                                  size='sm'
                                                  onClick={() => {
                                                      downloadSFDRDocument(
                                                          fund.fundCode,
                                                          2
                                                      )
                                                  }}
                                              >
                                                  View Disclosure
                                              </Button>
                                          </Stack>
                                          <Stack flexBasis='20%' px={'5px'}>
                                              <Text
                                                  label='Pre-contractual Disclosure'
                                                  variant='caption'
                                              />
                                              <Button
                                                  variant='secondary'
                                                  size='sm'
                                                  onClick={() => {
                                                      downloadSFDRDocument(
                                                          fund.fundCode,
                                                          1
                                                      )
                                                  }}
                                              >
                                                  View Disclosure
                                              </Button>
                                          </Stack>
                                          <Stack flexBasis='20%' px={'5px'}>
                                              <Text
                                                  label='Periodic Disclosure'
                                                  variant='caption'
                                              />
                                              <Button
                                                  variant='secondary'
                                                  size='sm'
                                                  onClick={() => {
                                                      downloadSFDRDocument(
                                                          fund.fundCode,
                                                          0
                                                      )
                                                  }}
                                              >
                                                  View Disclosure
                                              </Button>
                                          </Stack>
                                      </Flex>
                                  </Box>
                              )
                          })
                        : ''}
                </Box>
            </Wrapper>
        </Layout>
    )
}
