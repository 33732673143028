import { Typography } from '@material-ui/core'
import ListSubheader from '@material-ui/core/ListSubheader'
import TextField from '@material-ui/core/TextField'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import { ListChildComponentProps, VariableSizeList } from 'react-window'

const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING,
        },
    })
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null)
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true)
        }
    }, [data])
    return ref
}

// Adapter for react-window
export const ListboxComponent = React.forwardRef<HTMLDivElement>(
    function ListboxComponent(props, ref) {
        const { children, ...other } = props
        const itemData = React.Children.toArray(children)
        const theme = useTheme()
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
        const itemCount = itemData.length
        const itemSize = smUp ? 36 : 48

        const getChildSize = (child: React.ReactNode) => {
            if (React.isValidElement(child) && child.type === ListSubheader) {
                return 48
            }

            return itemSize
        }

        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
        }

        const gridRef = useResetCache(itemCount)

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2 * LISTBOX_PADDING}
                        width='100%'
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType='ul'
                        itemSize={(index) => getChildSize(itemData[index])}
                        overscanCount={5}
                        itemCount={itemCount}
                    >
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        )
    }
)

const useStyles = makeStyles({
    listbox: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
})

export interface AutocompleteInputProps {
    data: any
    onChange: any
    value: any
}

export const AutocompleteInput: React.FC<AutocompleteInputProps> = (props) => {
    const { data } = props

    const classes = useStyles()

    return (
        <Autocomplete
            id='virtualize-demo'
            style={{ width: 300 }}
            disableListWrap
            classes={classes}
            ListboxComponent={
                ListboxComponent as React.ComponentType<
                    React.HTMLAttributes<HTMLElement>
                >
            }
            options={data.length > 0 ? data : ['']}
            renderInput={(params) => (
                <TextField {...params} variant='outlined' />
            )}
            renderOption={(option: string) => (
                <Typography noWrap>{option}</Typography>
            )}
        />
    )
}
