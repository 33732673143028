import { RouteComponentProps } from '@reach/router'
import { SFDR } from 'common/components/organisms/FundCentre/SFDR'
import { getLayoutData } from 'common/utils/getLayoutData'
import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import React from 'react'

export interface SfdrProps extends RouteComponentProps {
    pageContext: any
}

export const SfdrPage: React.FC<SfdrProps> = ({ pageContext }) => {
    return <SFDR {...getSFDRData(pageContext)} />
}

export const getSFDRData = (data: any) => {
    return {
        jumbotron: getJumbotronData(data),
        layout: getLayoutData(data)
    }
}

export default SfdrPage
